import React,{ useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import '../../Assets/ScrollImages/CA.png'


function Type2(props) {
  
  const [titles, settitles] = useState([
    "WEF",
          "CA",
          "UCT",
          "RUSI",
          "Vive",
          "Cortex",
          "Journey",
          "CDE",
          "Uplink",
          "FX",
          "SweepSouth",
          "CDOE",
          "MOHARA",
          "SAA"
  ]);

  const [index, setIndex] = useState(props.startIndex);
  const [delay, setDelay] = useState(props.delay)

  console.log(`${delay}s`)


  const onAnimationIteration = () => {
    setIndex((prevIndex) => {
      // reset index if current index is greater than array size
      return prevIndex + 1 < titles.length ? prevIndex + 1 : 0;
    });
  };

  const returnImage = (index) =>{

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // development build code
      const target = `src/Assets/ScrollImages/${titles[index]}.png`
      console.log(target)
      return <img src={target} style={{width:120, height:120 , padding:"10px", marginRight: "120px"}} alt="logo"/>
  } else {
      // production build code
      const target = require(`../../Assets/ScrollImages/${titles[index]}.png`);
      console.log(target)
      return <img src={target}  style={{width:120, height:120, padding:"10px", marginRight: "120px"}} alt="logo"/>
  }


  }

  return (

    <div className="flex w-full mx-auto">
      <Marquee gradient={true} gradientWidth={100} autoFill={true}>
        {titles.map((item, index) => returnImage(index))}
      </Marquee>
    </div>

  );
}

export default Type2;
