import React, { useState, useEffect } from "react";
import Type from "./Type";
import Home2 from "./Home2";
import Intersection from "./Intersection";


function Home() {

  return (
    <section className="bg-white">

    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <h1 className="home-name font-light">Edwin Kassier</h1>
      <Type delay={0} />
      <div className=" pt-32"/>
      <Home2/>
    </div>
  </section>
  );
}

export default Home;
