import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Timeline } from "@/components/ui/timeline";
import { AuroraText } from "@/components/ui/aurora-text";
import { SpectrumVisualizer, SpectrumVisualizerTheme } from 'react-audio-visualizers';

export function Prometheus() {
  const data = [
    {
      title: "Purpose",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            So, Project Prometheus. What's it all about?  Basically, I'm trying to build a digital version of myself – like, how close can we actually get to making a computer feel like you're talking to *me*?  It's a bit of an experiment to see if we can create a real reflection of who I am, digitally.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://willdam20.wordpress.com/wp-content/uploads/2020/10/cq5dam.web_.1280.1280.jpeg?w=1024"
              alt="Illustration of purpose"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Model and Technology Design",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            Okay, so how do we make this digital me happen?  The brain of the operation is Hermes, a language model that's already pretty good at text conversations. But to really make it *me*, it needs a voice! That's where things get interesting. I'm checking out two cool tools: Tortoise TTS and Coqui TTS.  The idea is to give Hermes a voice that sounds just like mine.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://6187708.fs1.hubspotusercontent-na1.net/hubfs/6187708/Depositphotos_336298336_XL.jpeg"
              alt="Illustration of technology design"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Training Data",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            To teach these TTS models to sound like *me*, they need to hear me talk!  So, I need to feed them samples of my voice. Not just random words, but a good mix of sounds and even some different emotions.  This way, the system can really pick up on how I naturally speak and express myself.  To get started, I recorded a sample that covers all the letters of the alphabet and my name – it’s like a benchmark to see how well these models are learning. Check it out below!
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://imageio.forbes.com/specials-images/imageserve/65033b85fb3035d47a2250b8/0x0.jpg?format=jpg&height=600&width=1200&fit=bounds"
              alt="Illustration of training data"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>

          <div className="flex justify-center mt-4">
            <audio controls className="w-full rounded-md">
              <source src="https://storage.googleapis.com/ashes_project_website_artifacts/audio/TrainingInput.wav" type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      ),
    },
    {
      title: "TTS Model Comparisons",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            So, I knew I needed a TTS model, but finding the *right* one? That was a mission!  Turns out, the world of Text-to-Speech is kinda secretive and focused on making money, so good open-source options are surprisingly hard to find. But, after digging around, I narrowed it down to two of the best open-source models out there right now. Let's see how they stack up!
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://img.freepik.com/free-photo/colorful-duo-tone-background_23-2148569177.jpg"
              alt="Comparison Image"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
      title: "TTS Model Comparisons: Coqui TTS",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            First up in our TTS showdown is Coqui TTS. This library is a big deal in the TTS world, packed with different models like Tacotron, VITS, and Glow-TTS. It’s all about being versatile, handling tons of languages, and even doing things like voice cloning and switching between speakers.  Plus, Coqui gives you the tools to train and tweak models yourself, and there’s a good community around it if you get stuck.  It's a solid, all-around choice for both playing around and real projects.
          </p>
          <div className="flex justify-center relative"> {/* Image container with relative positioning */}
            <LazyLoadImage
              src="https://opengraph.githubassets.com/31078a40cfb564f4b0e3a1b7521240647a0c7d4272213a5f3c176b10cb3cf715/coqui-ai/TTS"
              alt="Comparison Image"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
             <div className="absolute top-2 right-2"> {/* Button now inside image container */}
                <a href="https://github.com/coqui-ai/TTS?tab=readme-ov-file" target="_blank" rel="noopener noreferrer">
                  <button className="bg-white text-black p-2 rounded-full border border-neutral-300 hover:shadow-md transition-shadow duration-200 flex items-center justify-center">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" alt="GitHub" className="w-5 h-5" />
                  </button>
                </a>
              </div>
          </div>
          <div className="flex justify-center mt-4">
            <audio controls className="w-full rounded-md">
              <source src="https://storage.googleapis.com/ashes_project_website_artifacts/audio/XTTSOutput.wav" type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      ),
    },
    {
      title: "TTS Model Comparisons: E2/F5 TTS",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            But hold on, there’s another contender: E2/F5 TTS.  Like Coqui, it’s also a TTS library with a bunch of models, but it seems to really shine when it comes to voice cloning, especially with its "one-shot" cloning.  This means it can do a pretty amazing job of mimicking a voice from just a single, short sample. Let's see how it sounds!
          </p>
          <div className="flex justify-center relative"> {/* Image container with relative positioning */}
            <LazyLoadImage
              src="https://cdn-thumbnails.huggingface.co/social-thumbnails/spaces/ThreadAbort/E2-F5-TTS.png"
              alt="Comparison Image"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
             <div className="absolute top-2 right-2"> {/* Button now inside image container */}
                <a href="https://github.com/SWivid/F5-TTS" target="_blank" rel="noopener noreferrer">
                  <button className="bg-white text-black p-2 rounded-full border border-neutral-300 hover:shadow-md transition-shadow duration-200 flex items-center justify-center">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" alt="GitHub" className="w-5 h-5" />
                  </button>
                </a>
              </div>
          </div>
          <div className="flex justify-center mt-4">
            <audio controls className="w-full rounded-md">
              <source src="https://storage.googleapis.com/ashes_project_website_artifacts/audio/E2%3AFEOutput.wav" type="audio/wav" />
              </audio>
          </div>
        </div>
      ),
    },
    {
      title: "E2/F5 TTS: The Winner for Realistic Voice Cloning",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            Okay, after testing them out, it's pretty clear: E2/F5 TTS is the winner for voice cloning that sounds seriously real, especially with that one-shot trick.  It just nails the little details and nuances of a voice from just a tiny sample.  Plus, it seems to be better at avoiding weird accent issues that can pop up with other TTS systems.  Basically, it gives a cleaner, more authentic voice clone that really sounds like the person you're aiming for.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://static.vecteezy.com/system/resources/previews/011/351/201/non_2x/elegant-golden-scene-diagonal-glowing-with-lighting-effect-sparkle-on-black-background-template-premium-award-design-vector.jpg"
              alt="E2/F5 TTS Winner Image"
              width={500}
              height={500}
              className="rounded-lg object-fit h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
        title: "Digital likeness",
        content: (
          <div className="space-y-8">
            <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
              So, we've got a voice!  With the voice cloning working nicely, the next piece of the puzzle is the visual side.  I'm starting to look into cloning my *physical* appearance too, and figuring out how to control this whole digital double.  It's all about making it feel like a real, interactive digital version of me!
            </p>
            <div className="flex justify-center">
              <LazyLoadImage
                src="https://artofvfx.com/wp-content/uploads/2020/07/Westworld_S3_ILP_ITW_01B.jpg"
                alt="E2/F5 TTS Winner Image"
                width={500}
                height={500}
                className="rounded-lg object-fit h-40 md:h-60 lg:h-80 w-full"
              />
            </div>
          </div>
        ),
      },
      {
        title: "Digital likeness - Starting Point",
        content: (
          <div className="space-y-8">
            <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
              In doing my research there are few free or open source options to create a digital twin. Having said that, there is one interesting thing I found, it would seem the Games industry is very interested in creating unique characters for their games, and thus they are actively working on creating technologies to clone peoples faces and movements to create more realistic game envrionments. So this has given us a starting point, can we use some of the tools in the gaming industry to create a fully rigged facial mesh that I can use for syncing to my voice files. First step is creating a high fidelity facial scan of my head and shoulders. After that our job is to use that 3D scan and map it to a facial mesh.
            </p>
            <div className="flex justify-center">
              <LazyLoadImage
                src="https://di4d.com/wp-content/uploads/2021/02/NEWS_SNAPPERS.jpg"
                alt="E2/F5 TTS Winner Image"
                width={500}
                height={500}
                className="rounded-lg object-fit h-40 md:h-60 lg:h-80 w-full"
              />
            </div>
          </div>
        ),
      },
      {
        title: "Digital likeness - 3D Head Scan",
        content: (
          <div className="space-y-8">
            <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
              Reality Scan
            </p>
            <div className="flex justify-center">
              <LazyLoadImage
                src="https://cdn2.unrealengine.com/1920x1080-1920x1080-a3545a9d33a4.png"
                alt="E2/F5 TTS Winner Image"
                width={500}
                height={500}
                className="rounded-lg object-fit h-40 md:h-60 lg:h-80 w-full"
              />
            </div>
          </div>
        ),
      },
  ];

  return (
    <div>
      <div className="overflow-hidden" style={{ animation: "fadeIn 2s" }}>
        <Timeline data={data} />
      </div>
    </div>
  );
}